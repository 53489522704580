import React, { useState } from 'react';
import { Container, Grid, Typography, Box, Paper, Button, Modal } from '@mui/material';
//import { Container, Grid, Typography, Box, Paper, Button } from '@mui/material';
import HeadshotAndDescription from '../components/providers/ClientsPhotos';
import PageTitle from '../components/PageTitle';
import { Helmet } from 'react-helmet-async';
import TeamGrid from '../components/clientsComponents/teamGrid.js';


const DownloadModal = ({ open, handleClose, fileUrl, fileName }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, textAlign: 'center' }}>
        <Typography variant="h6">Confirm Download</Typography>
        <Typography variant="body1">Are you sure you want to download {fileName}?</Typography>
        <Box mt={2}>
          <Button variant="contained" onClick={handleDownload} sx={{ mr: 1 }}>Yes</Button>
          <Button variant="outlined" onClick={handleClose}>No</Button>
        </Box>
      </Box>
    </Modal>
  );
};


const Patients = () => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ fileUrl: '', fileName: '' });

  const handleOpenModal = (fileUrl, fileName) => {
    setSelectedFile({ fileUrl, fileName });
    setModalOpen(true);
  };


  return (
    <Container maxWidth="xl" sx={{ overflowX: 'hidden', padding: 0 }}>
    {/* Helmet for Clients Page */}
    <Helmet>
        <title>Client Services - Northland Psychological Services</title>
        <meta
          name="description"
          content="Access online counseling services with Northland Psychological Services. Our clients can easily connect with providers online and complete necessary forms for therapy."
        />
        <meta
          name="keywords"
          content="Telehealth Counseling, Online Therapy, Client Forms, Mental Health Services, Northland Psychological Services, Telehealth for Clients, Hermantown MN"
        />
        <meta property="og:title" content="Client Services - Northland Psychological Services" />
        <meta
          property="og:description"
          content="Access telehealth counseling services with Northland Psychological Services. Our clients can easily connect with providers online and complete necessary forms for therapy."
        />
        <meta property="og:url" content="https://northlandpsychological.com/clients" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://northlandpsychological.com/clients" /> {/* Update to full URL */}
      </Helmet>
      <PageTitle
        title="Clients"
        description={
          <>
            Welcome to Northland Psychological Services' Clients Page! You will find Doxy links for telehealth below your respective provider and necessary documents further down!
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: 'black', color: 'white' }
                  }}
                  href="#forms"
                >
                  Forms
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: 'black', color: 'white' }
                  }}
                  href="https://pp-wfe-101.advancedmd.com/137030/account/logon" 
                  target='_blank'
                >
                  Patient Portal
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <TeamGrid /> {/* This calls the components/cliensComponents/teamGrid and builds the provider/doxy link section */}
     

      <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px", backgroundColor: "#4f5a5e" }}>
        <Box id="forms">
          <Typography variant="h4" gutterBottom color="common.white">
            Forms
          </Typography>
          <Typography variant="body1" color="common.white">
            Here you will find all necessary forms!
          </Typography>


        </Box>
      </Paper>

      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "15px" }}>
                <Typography variant="h6" gutterBottom>General Forms</Typography>
                <Grid container
                  direction="column"
                  spacing={2}
                  alignItems="stretch">
                  <Grid item>
                     <a
                      //onClick={() => handleOpenModal('assets/HealthForms/ROI-NPS.pdf', 'Release Of Information.pdf')}
                      href='assets/HealthForms/ROI.pdf'
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    > 
                      <Button
                        variant="contained"
                        //onClick={() => handleOpenModal('assets/HealthForms/ROI-NPS.pdf', 'Release Of Information.pdf')}
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Release Of Information
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      href="/privacy-policy"
                    >
                      Privacy Policy
                    </Button>
                  </Grid>
                  <Grid item>
                    <a
                      //href="assets/HealthForms/Records_Requst_Policy.docx" 
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleOpenModal('assets/HealthForms/Records_Requst_Policy.docx', 'Records Requst Policy')}
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Records Request Policy
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "15px" }}>
                <Typography variant="h6" gutterBottom>NHSC</Typography>
                <Typography variant="body1">National Health Service Corps forms</Typography>
                <Grid container
                  direction="column"
                  spacing={2}
                  alignItems="stretch">
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => handleOpenModal('assets/HealthForms/Non-Discrimination Policy (1).docx', 'Non-Discrimination Policy ')}

                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      //href="assets\HealthForms\Non-Discrimination Policy (1).docx"
                    >
                      Non-Discrimination Policy
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => handleOpenModal('assets/HealthForms/Sliding Fee Application 2024.docx', 'Sliding Fee Application ')}

                      fullWidth
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      //href="assets\HealthForms\Sliding Fee Application 2024.docx"
                    >
                      Discounted/Sliding Fee Application
                    </Button>
                  </Grid>
                  <Grid item>
                    <a
                      href="assets/HealthForms/Sliding Fee Policy 2024 NPS.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        //onClick={() => handleOpenModal('assets/HealthForms/Sliding Fee Policy 2024 NPS.pdf', 'Discounted/Sliding Fee Policy')}
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Discounted/Sliding Fee Policy
                      </Button>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="assets\HealthForms\Sliding Fee Schedule 2024.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': { backgroundColor: 'black', color: 'white' },
                        }}
                      >
                        Discounted/Sliding Fee Scale Percentages
                      </Button>
                    </a>
                  </Grid>




                </Grid>
              </Paper>
            </Box>
          </Grid>


        </Grid>
      </Container>




      <DownloadModal open={modalOpen} handleClose={() => setModalOpen(false)} fileUrl={selectedFile.fileUrl} fileName={selectedFile.fileName} />
    </Container>
  );
};

export default Patients;

